import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { padNumber } from "../util/Formatting";
import { useRouteLoaderData } from "react-router-dom";

export const CountdownCard: React.FC = () => {
    let cubeConDate = useCubeConDate();
    const [timeLeft, setTimeLeft] = useState(getTimeUntilCubeCon(cubeConDate));

    useEffect(() => {
        //If no time until, skip processing further
        if (!timeLeft) {
            return;
        }
        const timer = setTimeout(() => {
          setTimeLeft(getTimeUntilCubeCon(cubeConDate));
        }, 1000);

        //clear the timer on unmount
        return () => clearTimeout(timer);
      });

      if (!timeLeft || !cubeConDate) {
        return null;
      }

      return <Card>
        <CardHeader className="h5">CubeCon Countdown!</CardHeader>
        <CardBody>
            <Row>
                <CountDownDigit time={timeLeft.days} label="DAYS" />
                <CountDownDigit time={timeLeft.hours} label="HRS" />
                <CountDownDigit time={timeLeft.minutes} label="MINS" />
                <CountDownDigit time={timeLeft.seconds} label="SECS" />
            </Row>
        </CardBody>
      </Card>;
};

const CountDownDigit: React.FC<{ time: number, label: string }> = (props) => {
    const { time, label } = props;

    return <Col className="mx-auto">
        <div className="h4 text-center">{padNumber(time, 2)}</div>
        <div className="text-center">{label}</div>
    </Col>;
}

interface ITimeLeft {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

function useCubeConDate(): Date | undefined {
    const { importantdates } = useRouteLoaderData("root") as any;
    const cubecon = (importantdates as any[]).find(event => event.id === "cc");
    return cubecon && cubecon.date ? new Date(cubecon.date) : undefined;
}

function getTimeUntilCubeCon(cubeConDate: Date | undefined): ITimeLeft | undefined {
    if (!cubeConDate) {
        return undefined;
    }

    const difference = +cubeConDate - +new Date();
    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    else {
        return undefined;
    }
}