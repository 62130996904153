export function getApiUrl(): string {
	const { hostname } = window.location;
	if (hostname === 'localhost') {
		return 'http://localhost:9000/api';
	}
	return `https://${hostname}/api`;
}

export const API_URL = getApiUrl();

export async function apiRequest(api: string, params: string = "", accessToken?: string): Promise<any> {
	const headers = new Headers();
	if (accessToken) {
		headers.append("Authorization", "Bearer " + accessToken);
	}

	const options = {
		method: "GET",
		headers: headers
	}
	return fetch(buildApiRequest(api, params), options).then(res => {
		const type = res.headers.get("content-type");
		if (type?.startsWith("application/json")) {
			return res.json();
		}
		else {
			return res.text();
		}
	});
}

export async function apiPost(api: string, body: any, accessToken?: string): Promise<Response> {
	const headers = new Headers();
	if (accessToken) {
		headers.append("Authorization", "Bearer " + accessToken);
		headers.append("Content-Type", "application/json");
	}

	const options = {
		method: "POST",
		headers: headers,
		body: JSON.stringify(body)
	}
	return fetch(new Request(buildApiRequest(api), options));
}

function buildApiRequest(...args: string[]): string {
	return API_URL + (args.length > 0 ? "/" + args.join("/") : "");
}