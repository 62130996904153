import { useState, useCallback } from "react";

export const useCarousel = (length: number, ):
[ number, () => void, () => void, () => void, () => void] => {
    const [animating, setIsAnimating] = useState(false);
    const [activeIndex, setActiveIndex] = useState(Math.floor(Math.random() * length));

    const prev = useCallback(() => {
        if (animating) return;
        setActiveIndex(activeIndex === 0 ? length - 1 : activeIndex - 1);
    }, [setActiveIndex, activeIndex, animating, length]);

    const next = useCallback(() => {
        if (animating) return;
        setActiveIndex((activeIndex + 1) % length);
    }
    ,[setActiveIndex, activeIndex, animating, length]);

    const onExiting = useCallback(() => {
        setIsAnimating(true);
    }, [setIsAnimating]);

    const onExited = useCallback(() => {
        setIsAnimating(false);
    }, [setIsAnimating]);

    return [activeIndex, prev, next, onExiting, onExited];
};