import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import { cssJoin } from "../../util/Formatting";

interface ITimelineProps {
    events: ITimelineEvent[];
}

export const Timeline: React.FC<ITimelineProps> = (props) => {
    const { events } = props;

    const now = new Date();
    let foundEnd = false;
    for (let i = events.length - 1; i--; i>=0) {
        if (foundEnd) {
            events[i].class = "_timelineCircleFill";
        }
        else if (events[i].date && (now.getTime() - events[i].date!.getTime() > 0)) {
            foundEnd = true;
            events[i].class = "_timelineCircleEnd";
        }
    }

    return <div className="timeline">
        {events.map((event, index) => <TimelineItem event={event} key={index }/>)}
    </div>;
};

export interface ITimelineEvent {
    name: string;
    date?: Date;
    link?: string;
    class?: string;
}

const TimelineItem: React.FC<{ event: ITimelineEvent }> = (props) => {
    const { event } = props;

    return <div className="timelineItem">
        <div className="timelineArea"><div className={cssJoin("timelineCircle", event.class)} /></div>
        <Col className="timelineItemContents">
            <div>{event.date ? event.date.toDateString() : "TBD"}</div>
            {event.link ? <Link to={event.link} className="link-dark">{event.name}</Link> : <div>{event.name}</div>}
        </Col>
    </div>
};