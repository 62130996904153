import { Carousel, CarouselItem, CarouselControl, Card, CardHeader, Col } from "reactstrap"
import { ISponsor } from "../models/DataModels"
import { useCarousel } from "../hooks/useCarousel";
import { useRouteLoaderData } from "react-router-dom";
import { EasyLink } from "./helpers/EasyLink";


export const SponsorsBanner: React.FC = () => {
    const { sponsors } = useRouteLoaderData("root") as { sponsors: ISponsor[] };

    const [activeIndex, prev, next, onExiting, onExited] = useCarousel(sponsors.length);

    const items = sponsors.map(sponsor => <CarouselItem 
        key={sponsor.name} onExiting={onExiting} onExited={onExited} >
        <Sponsor sponsor={sponsor} />
    </CarouselItem>);
    
    return <Card>
        <CardHeader className="h5">Sponsors</CardHeader>
        <Carousel dark ride="carousel" activeIndex={activeIndex} previous={prev} next={next} className="my-2">
            {items}
            <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={prev}
            />
            <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
            />
    </Carousel></Card>;
};

const Sponsor: React.FC<{ sponsor: ISponsor}> = ({ sponsor }) => {
    return <Col>
        <img src={sponsor.imagePath} className="iconSmall w-100" alt={sponsor.name} />
        <div className="text-center">
            { sponsor.link ? <EasyLink stretch href={sponsor.link}>{sponsor.name}</EasyLink> : sponsor.name }
        </div>
    </Col>;
}