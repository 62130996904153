import { useRouteError } from "react-router-dom";
import { Card, CardBody, CardTitle } from "reactstrap";

export const ErrorPage: React.FC = () => {
  const error = useRouteError() as any; //they didn't type their errors?

  return <Card>
    <CardBody>
        <CardTitle tag={"h3"}>Oops, you hit a bug!</CardTitle>
        <CardBody>
            {error.statusText || error.message}
        </CardBody>
    </CardBody>
  </Card>;
}