import { SponsorsBanner } from "./SponsorsBanner";
import { CountdownCard } from "./CountdownCard";
import { ImportantDatesCard } from "./ImportantDatesCard";

interface IInfoSidebarProps {
    noSponsors?: boolean;
}

export const InfoSidebar: React.FC<IInfoSidebarProps> = (props) => {
    const { noSponsors } = props;
    
    return <>
        {!noSponsors ? <div className="my-3"><SponsorsBanner /></div> : null}
        <div className="my-3"><CountdownCard /></div>
        <div className="my-3"><ImportantDatesCard /></div>
    </>;
};