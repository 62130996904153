import { useCallback, useState } from 'react';
import { Collapse, Container, DropdownItem, DropdownMenu, Nav, NavItem, Navbar, NavLink, NavbarBrand, NavbarToggler, DropdownToggle, Dropdown } from 'reactstrap';
import { IPageInfo, Pages } from '../models/Constants';
import * as DOM from 'react-router-dom';
import { LoginItem } from './helpers/LoginLogout';
import { ContactUsItem } from './ContactUsItem';
import { useRouteLoaderData } from 'react-router-dom';

export const HeaderSection: React.FC = () => {
	const { navbar } = useRouteLoaderData("root") as { navbar: IPageInfo[] };
	const [isOpen, setIsOpen] = useState(false);
	const toggle = useCallback(() => setIsOpen(!isOpen), [setIsOpen, isOpen]);

	//todo move to server for config
	const navItems = [
		Pages.CubeCon,
		...navbar,
		Pages.Shop,
		Pages.Cubes,
		Pages.Gallery,
		Pages.FAQ,
		Pages.About,
	];

	return <div className="bg-dark"><Container><Navbar color="dark" dark expand="md" className="test">
		<NavbarBrand tag={DOM.Link} to={"/"}><img className="imageFit iconSmall" src="/assets/cubeconHeaderWithDate.png" alt="Cubecon Icon" style={{ maxWidth: "100%" }} /></NavbarBrand>
		<NavbarToggler onClick={toggle} />
		<Collapse isOpen={isOpen} navbar>
			<Nav navbar>
				{navItems.map(page => <TabNavItem key={page.title} page={page} />)}
				<ContactUsItem />
				<LoginItem />
			</Nav>
		</Collapse>
	</Navbar></Container></div>;
};

interface TabNavItemProps {
	page: IPageInfo;
}

//this looks silly right now but eventually I want to have a way to toggle pages on or off instead of editing the Header section
const TabNavItem = (props: TabNavItemProps) => {
	const { page } = props;
	const [dropdownOpen, setDropdownOpen] = useState(false);

	if (page.subPages?.length ?? 0 > 1) {
		return <Dropdown nav inNavbar isOpen={dropdownOpen} onMouseOver={() => setDropdownOpen(true)} onMouseOut={() => setDropdownOpen(false)} toggle={() => {}} className="navDropdown ccNavItem">
			<DropdownToggle nav caret tag={DOM.NavLink} to={page.href}>{page.title}</DropdownToggle>
			<DropdownMenu dark>
				{page.subPages?.map(subPage => <DropdownItem key={subPage.href} tag={DOM.NavLink} to={subPage.href} disabled={subPage.disabled}>{subPage.title}</DropdownItem>)}
			</DropdownMenu>
		</Dropdown>;
	}
	else {
		return <NavItem>
			<NavLink tag={DOM.NavLink} to={page.href} className="ccNavItem">{page.title}</NavLink>
		</NavItem>;
	}
};
