import React from "react";
import { IWebPageContext } from "./DataModels";

export interface IPageInfo {
	href?: string;
	title: string;
	subPages?: IPageInfo[];
	description?: string;
	icon?: string;
	disabled?: boolean;
}

//TODO - cube years (and likely gallery) should be based on data we load from the db
export class Pages {


	//Live Now
	static Home: IPageInfo = { href: '/', title: "Home" };
	static CubeCon: IPageInfo = {
		href: '/CubeCon2024', title: "CubeCon 2024", subPages: [
			{ href: "/CubeCon2024/tickets", title: "Tickets", description: "Information on how to attend.", icon: "/assets/cubeconTicket.png" },
			{ href: "/CubeCon2024/hotels", title: "Hotel Info", description: "Information on hotel blocks.", icon: "/assets/hotel.png" },
			{ href: "/CubeCon2024/schedule", title: "Schedule", description: "Main and side event schedule for CubeCon 2024.", icon: "/assets/calendar.png" },
			//{ href: "/CubeCon2024/submit", title: "Cube Submission", description: "Submit your Cube to be featured at CubeCon 2024.", icon: "/assets/form.png" },
			{ href: "/cubes/2024", title: "Cubes", description: "The featured Cubes at CubeCon 2024.", icon: "/assets/cubeIcon.png" },
			{ href: "CubeCon2024/guests", title: "Special Guests", description: "The wonderful people we're happy to be hosting at CubeCon 2024", icon: "/assets/vip.png" }
		]
	};
	static Cubes: IPageInfo = {
		href: '/cubes', title: "Cubes", subPages: [
			{ href: "/cubes/2024", title: "This Year", description: "The featured Cubes at CubeCon 2024", icon: "" },
			{ href: "/vote", title: "Cube Voting", description: "Look at the voting that was held for Cubes to be featured at CubeCon 2024.", icon: "/assets/vote.png" },
			{ href: "/cubes/2023", title: "2023", description: "The featured Cubes at CubeCon 2023", icon: "" },
			{ href: "/cubes/2022", title: "2022", description: "The featured Cubes at CubeCon 2022", icon: "" },
		]
	}
	static Gallery: IPageInfo = {
		href: '/gallery', title: "Gallery", subPages: [
			{ href: "/gallery/2023", title: "2023", description: "Photos from CubeCon 2023", icon: "/assets/gallery/2023/index.jpg" },
			{ href: "/gallery/2022", title: "2022", description: "Photos from CubeCon 2022", icon: "/assets/gallery/2022/index.jpg" },
		]
	};
	static FAQ: IPageInfo = { href: '/faq', title: "FAQ" };
	static Shop: IPageInfo = { href: '/shop', title: "Shop" };
	static About: IPageInfo = {
		href: '/about', title: "About Us", subPages: [
			{ href: "/about/staff", title: "Staff", description: "Get to know the people who are building the Con" },
			{ href: "/about/sponsors", title: "Sponsors", description: "View the sponsors that make CubeCon possible" },
		]
	};

	//Later
	static News: IPageInfo = { href: '/news', title: "News" };

}

export const SocialMediaDefinitions = {
	twitter: {
		websiteName: "Twitter",
		websitePath: "https://twitter.com/",
		imagePath: "/assets/twitter.png"
	},

	discord: {
		websiteName: "Discord",
		websitePath: "https://discordapp.com/users/",
		imagePath: "/assets/discord.png"
	},

	youtube: {
		websiteName: "YouTube",
		websitePath: "https://www.youtube.com/",
		imagePath: "/assets/youtube.png"
	},

	twitch: {
		websiteName: "Twitch",
		websitePath: "https://www.twitch.tv/",
		imagePath: "/assets/twitch.png"
	},

	link: {
		websiteName: "Link Tree",
		websitePath: "",
		imagePath: "/assets/link.png"
	}
}

export const CubecobraLinkPrefix = "https://cubecobra.com/cube/overview/";

export const WebPageContext = React.createContext<IWebPageContext>({}); // todo delete unused definition