import { IActivity } from "../models/DataModels";

export function padNumber(num: number, length: number): string {
	return num.toString().padStart(length, "0");
}

export function cssJoin(...args: any[]) { return args.filter(str => str?.length > 0).join(" ") }

const USD = Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

export function toUSD(amt: number) { return `${USD.format(amt)}` }

export function longFormDate(date: Date) {
	return new Intl.DateTimeFormat("en-US", { weekday: 'long', month: 'long', day: 'numeric', timeZone: 'America/Chicago' })
		.format(new Date(date));
}

export function shortFormDate(date: Date) {
	return new Intl.DateTimeFormat("en-US", { month: 'short', day: 'numeric', timeZone: 'America/Chicago' })
		.format(new Date(date));
}

export function displayTimeRange(activity: IActivity): string {
	return `${new Date(activity.startTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", timeZone: 'America/Chicago' })} - ${new Date(activity.endTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", timeZone: 'America/Chicago' })}`;
}


export function numWithSuffix(number: number): string {
	switch (number) {
		case 1:
			return "1st";
		case 2:
			return "2nd";
		case 3:
			return "3rd";
		case 4:
			return "4th";
		case 5:
			return "5th";
		case 6:
			return "6th";
		case 7:
			return "7th";
		case 8:
			return "8th";
		case 9:
			return "9th";
		default:
			return number.toString();
	}
}