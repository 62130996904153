import { Card, CardBody, CardHeader } from "reactstrap";
import { ITimelineEvent, Timeline } from "./helpers/Timeline";
import { useRouteLoaderData } from "react-router-dom";

export const ImportantDatesCard: React.FC = () => {
    const { importantdates } = useRouteLoaderData("root") as any;
    const events: ITimelineEvent[] = (importantdates as any[]).map<ITimelineEvent>(event => {
        return { name: event.name, date: event.date && !event.hideDate ? new Date(event.date) : undefined, link: event.link };
    }).sort((a,b) => a.date && b.date ? a.date.getTime() - b.date.getTime() : 0);
    
    return <Card>
        <CardHeader className="h5">Important Dates</CardHeader>
        <CardBody>
            <Timeline events={events} />
        </CardBody>
    </Card>;
}