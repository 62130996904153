import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { DefaultLayout, LayoutLoader } from "./layouts/DefaultLayout";
import { ErrorPage } from "./pages/ErrorPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/cubeconstyle.css';
import "yet-another-react-lightbox/styles.css";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { useMemo } from "react";

interface CreateRouter {
	pca: PublicClientApplication;
}

const createRouter = ({ pca }: CreateRouter) => createBrowserRouter([
	{
		path: "/",
		loader: LayoutLoader,
		element: <DefaultLayout />,
		errorElement: <ErrorPage />,
		id: "root",
		children: [
			{ index: true, lazy: () => import("./pages/HomePage") },
			{
				path: "cubecon2024", children: [
					{ index: true, lazy: () => import("./pages/CubeConPage") },
					{ path: "schedule", lazy: () => import("./pages/SchedulePage") },
					{ path: "tickets", lazy: () => import("./pages/TicketPage") },
					{ path: "hotels", lazy: () => import("./pages/HotelPage") },
					{ path: "submit", lazy: () => import("./pages/CubeSubmissionPage") },
					{ path: "guests", lazy: () => import("./pages/GuestsPage") },
				]
			},
			{
				path: "vote", children: [
					{ index: true, lazy: () => import("./pages/VotingPage") },
					{ path: ":id", lazy: () => import("./pages/VotingPage") }
				]
			},
			{
				path: "cubes", children: [
					{ index: true, lazy: () => import("./pages/CubesPage") },
					{ path: ":year", lazy: () => import("./pages/CubesPage") }
				]
			},
			{
				path: "gallery", children: [
					{ index: true, lazy: () => import("./pages/GalleryPage") },
					{ path: ":year", lazy: () => import("./pages/GalleryPage") }
				]
			},
			{ path: "faq", lazy: () => import("./pages/FAQPage") },
			{ path: "shop", lazy: () => import("./pages/ShopPage") },
			{
				path: "about", children: [
					{ index: true, lazy: () => import("./pages/AboutPage") },
					{ path: "staff", lazy: () => import("./pages/StaffPage") },
					{ path: "sponsors", lazy: () => import("./pages/SponsorsPage") }
				]
			},
		],
	},
]);

interface AppParams extends CreateRouter {};

export const App: React.FC<AppParams> = (params: AppParams) => {
	const { pca } = params;

	const router = useMemo(() => createRouter({ pca }), [pca]);

	return <MsalProvider instance={pca}>
		<RouterProvider router={router} />
	</MsalProvider>;
};